@font-face {
  font-family: 'apollon-font-icon';
  src: url('../fontIcons/apollon-font-icon.eot?whwr2h');
  src:
    url('../fontIcons/apollon-font-icon.eot?whwr2h#iefix')
      format('embedded-opentype'),
    url('../fontIcons/apollon-font-icon.ttf?whwr2h') format('truetype'),
    url('../fontIcons/apollon-font-icon.woff?whwr2h') format('woff'),
    url('../fontIcons/apollon-font-icon.svg?whwr2h#apollon-font-icon')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'apollon-font-icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.apollon-newHome:before {
  content: '\e975';
}
.apollon-Arrowdown:before {
  content: '\e971';
}
.apollon-Arrowleft:before {
  content: '\e972';
}
.apollon-Arrowright:before {
  content: '\e973';
}
.apollon-Arrowup:before {
  content: '\e974';
}
.apollon-Lock:before {
  content: '\e96f';
}
.apollon-send:before {
  content: '\e970';
}
.apollon-alarm:before {
  content: '\e967';
}
.apollon-checkIn:before {
  content: '\e968';
}
.apollon-colne:before {
  content: '\e969';
}
.apollon-documentText:before {
  content: '\e96a';
}
.apollon-drag:before {
  content: '\e96b';
}
.apollon-edit-2:before {
  content: '\e96c';
}
.apollon-meter:before {
  content: '\e96d';
}
.apollon-preCheck:before {
  content: '\e96e';
}
.apollon-entire-house:before {
  content: '\e947';
}
.apollon-face-scanner:before {
  content: '\e948';
}
.apollon-family:before {
  content: '\e949';
}
.apollon-features:before {
  content: '\e94a';
}
.apollon-filter:before {
  content: '\e94b';
}
.apollon-fire-extinguisher:before {
  content: '\e94c';
}
.apollon-fire:before {
  content: '\e94d';
}
.apollon-floorplan:before {
  content: '\e94e';
}
.apollon-fluent-play:before {
  content: '\e94f';
}
.apollon-area:before {
  content: '\e950';
}
.apollon-home-wifi:before {
  content: '\e951';
}
.apollon-income:before {
  content: '\e952';
}
.apollon-information:before {
  content: '\e953';
}
.apollon-invoice:before {
  content: '\e954';
}
.apollon-key-square:before {
  content: '\e955';
}
.apollon-key:before {
  content: '\e956';
}
.apollon-location:before {
  content: '\e957';
}
.apollon-like:before {
  content: '\e958';
}
.apollon-listing:before {
  content: '\e959';
}
.apollon-medal-star:before {
  content: '\e95a';
}
.apollon-message:before {
  content: '\e95b';
}
.apollon-min-max:before {
  content: '\e95c';
}
.apollon-more:before {
  content: '\e95e';
}
.apollon-new-home:before {
  content: '\e95f';
}
.apollon-news:before {
  content: '\e960';
}
.apollon-note:before {
  content: '\e961';
}
.apollon-notification:before {
  content: '\e962';
}
.apollon-open-eye:before {
  content: '\e963';
}
.apollon-apartment:before {
  content: '\e964';
}
.apollon-paid:before {
  content: '\e965';
}
.apollon-pending:before {
  content: '\e966';
}
.apollon-unpaid:before {
  content: '\e900';
}
.apollon-user-octagon:before {
  content: '\e901';
}
.apollon-video-camera:before {
  content: '\e902';
}
.apollon-view-area:before {
  content: '\e903';
}
.apollon-volume-up:before {
  content: '\e904';
}
.apollon-work:before {
  content: '\e905';
}
.apollon-zoom:before {
  content: '\e906';
}
.apollon-pet:before {
  content: '\e907';
}
.apollon-picture:before {
  content: '\e908';
}
.apollon-play:before {
  content: '\e909';
}
.apollon-plus:before {
  content: '\e90a';
}
.apollon-pool:before {
  content: '\e90b';
}
.apollon-price:before {
  content: '\e90c';
}
.apollon-process-:before {
  content: '\e90d';
}
.apollon-profile-setting:before {
  content: '\e90e';
}
.apollon-radius:before {
  content: '\e90f';
}
.apollon-real-state-info:before {
  content: '\e910';
}
.apollon-receive:before {
  content: '\e911';
}
.apollon-refresh:before {
  content: '\e912';
}
.apollon-report:before {
  content: '\e913';
}
.apollon-request:before {
  content: '\e914';
}
.apollon-saved:before {
  content: '\e915';
}
.apollon-search:before {
  content: '\e916';
}
.apollon-setting:before {
  content: '\e917';
}
.apollon-share:before {
  content: '\e918';
}
.apollon-sign-in:before {
  content: '\e919';
}
.apollon-sign-out:before {
  content: '\e91a';
}
.apollon-sign:before {
  content: '\e91b';
}
.apollon-single-accupancy:before {
  content: '\e91c';
}
.apollon-sofa:before {
  content: '\e91d';
}
.apollon-square:before {
  content: '\e91e';
}
.apollon-streetview:before {
  content: '\e91f';
}
.apollon-students:before {
  content: '\e920';
}
.apollon-swap:before {
  content: '\e921';
}
.apollon-terms:before {
  content: '\e922';
}
.apollon-tick:before {
  content: '\e923';
}
.apollon-trash:before {
  content: '\e924';
}
.apollon-tree:before {
  content: '\e925';
}
.apollon-trust:before {
  content: '\e926';
}
.apollon-camera:before {
  content: '\e927';
}
.apollon-duplicate:before {
  content: '\e928';
}
.apollon-edit:before {
  content: '\e929';
}
.apollon-about-us:before {
  content: '\e92a';
}
.apollon-add-photo:before {
  content: '\e92b';
}
.apollon-add:before {
  content: '\e92c';
}
.apollon-arrow-down:before {
  content: '\e92d';
}
.apollon-arrow-left:before {
  content: '\e92e';
}
.apollon-arrow-right:before {
  content: '\e92f';
}
.apollon-arrow-up:before {
  content: '\e930';
}
.apollon-balcon:before {
  content: '\e931';
}
.apollon-bank:before {
  content: '\e932';
}
.apollon-bath:before {
  content: '\e933';
}
.apollon-bed:before {
  content: '\e934';
}
.apollon-bill:before {
  content: '\e935';
}
.apollon-calendar:before {
  content: '\e936';
}
.apollon-car:before {
  content: '\e937';
}
.apollon-card-tick:before {
  content: '\e938';
}
.apollon-smoke:before {
  content: '\e939';
}
.apollon-clock:before {
  content: '\e93a';
}
.apollon-close-eye:before {
  content: '\e93b';
}
.apollon-close-small:before {
  content: '\e93c';
}
.apollon-close-square:before {
  content: '\e93d';
}
.apollon-contact-us-menu:before {
  content: '\e93e';
}
.apollon-contact-us-2:before {
  content: '\e93f';
}
.apollon-contract-agreement:before {
  content: '\e940';
}
.apollon-3D:before {
  content: '\e941';
}
.apollon-dashboard:before {
  content: '\e942';
}
.apollon-dislike:before {
  content: '\e943';
}
.apollon-down:before {
  content: '\e944';
}
.apollon-download:before {
  content: '\e945';
}
.apollon-entire-flat:before {
  content: '\e946';
}
.apollon-minus:before {
  content: '\e95d';
}
