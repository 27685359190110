.styles_react-code-input__CRulA > input {
  border: 1px solid #edeff1 !important;
  background: #f7f8f9 !important;
  font-family: 'Inter', sans-serif !important;
  color: #000 !important;
  margin: 0px 7px !important;
  width: 53px !important;
  height: 60px !important;
  border-radius: 12px !important;
  font-weight: bold !important;
}

.styles_react-code-input__CRulA > input:first-child {
  margin-left: 0 !important;
}

.styles_react-code-input__CRulA > input:last-child {
  margin-right: 0 !important;
}

.styles_react-code-input__CRulA > input:focus {
  border-color: #006ffd !important;
}

.styles_react-code-input__CRulA {
  display: flex;
  justify-content: space-between;
}

.styles_react-code-input-container__tpiKG {
  width: 100% !important;
}
