.leaflet-top,
.leaflet-bottom {
  z-index: 996 !important;
}

.leaflet-bottom.leaflet-right {
  display: none !important;
}

/* .leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
    left: -83px !important;
}

@media screen and (min-width: 768px) {
    .leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
        left: -108px !important;
    }
} */

.leaflet-popup-content {
  margin: 14px 20px !important;
}
