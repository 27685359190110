.btn-google-login span {
  display: none;
}

.btn-google-login div {
  margin-right: 0 !important;
}

/* .nsm7Bb-HzV7m-LgbsSe-BPrWId {
    display: none !important;
} */

/* .S9gUrf-YoZ4jf {
    width: 100%;
} */

.uaxL4e-RbRzK {
  border-radius: 8px !important;
  border: 1px solid #edeff1 !important;
}

.nsm7Bb-HzV7m-LgbsSe-bN97Pc-sM5MNb {
  display: flex;
  justify-content: center !important;
}

/* @media (min-width:767px) {
    .S9gUrf-YoZ4jf {
        width: 42rem;
    }
}

@media (min-width:1279px) {
    .S9gUrf-YoZ4jf {
        width: 38rem;
    }
} */
