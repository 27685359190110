@import './nprogress.css';
@import './normalize.css';
@import './modal.css';
@import './map.css';
@import './input-code.css';
@import './flickity.css';
@import './swiper.css';
@import './btn-switch.css';
@import './datepicker.css';
@import './toastify.css';
@import './google-login.css';
@import './editor.css';
@import './table.css';
@import './player.css';
@import './tippy.css';

html {
  font-size: 62.5% !important;
}

body {
  line-height: 1.5 !important;
  font-size: 1.4rem !important;
  font-family: 'Inter', sans-serif !important;
  background: #fafbff !important;
}

label,
div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
li,
span {
  text-transform: lowercase;
}

li::first-letter,
label::first-letter,
div::first-letter,
h1::first-letter,
h2::first-letter,
h3::first-letter,
h4::first-letter,
h5::first-letter,
h6::first-letter,
p::first-letter,
a::first-letter,
span::first-letter {
  text-transform: capitalize;
}

textarea::placeholder,
input::placeholder {
  font-size: 1.2rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

pre {
  margin: 1rem !important;
}

@media screen and (min-width: 768px) {
  textarea::placeholder,
  input::placeholder {
    font-size: 1.3rem;
  }
}

@media screen and (min-width: 1280px) {
  textarea::placeholder,
  input::placeholder {
    font-size: 1.4rem;
    line-height: 25px;
  }
}

.paginate ul li.selected a {
  background: #006ffd;
  color: #fff !important;
}

.paginate .disabled svg {
  color: #929292 !important;
}

.container {
  max-width: 100% !important;
}

@media (min-width: 1700px) {
  .container {
    width: 1531 !important;
    padding: 0 10rem !important;
    margin: 0 auto;
  }
}

.line-clamp-1 {
  /* display: -webkit-box; */
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.webkit-line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

li {
  list-style: none;
}

.line-clamp-2 {
  /* display: -webkit-box; */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.webkit-line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.line-clamp-3 {
  /* display: -webkit-box; */
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.webkit-line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.section-space {
  margin: 32px 0px;
}

.section-space-b {
  margin-bottom: 32px;
}

.section-space-t {
  margin-top: 32px;
}

@media screen and (min-width: 768px) {
  .section-space {
    margin: 42px 0px;
  }

  .section-space-b {
    margin-bottom: 42px;
  }

  .section-space-t {
    margin-top: 42px;
  }
}

textarea:focus,
input:focus {
  outline: none;
}

.shadow-card {
  transition: all 0.3s ease;
}

.shadow-card:hover {
  box-shadow: 12.1636px 12.1636px 30.409px rgba(37, 73, 211, 0.1);
}

.btn-search svg {
  fill: transparent !important;
}

/* .styles_react-code-input-container__tpiKG {
    margin: auto
} */
.align {
  text-align: center;
}

.direction-rtl {
  direction: rtl;
}

.direction-ltr {
  direction: ltr;
}

@media screen and (min-width: 768px) {
  .align {
    text-align: left;
  }
}

.svg-primary svg {
  fill: #006ffd;
}

.svg-outLine svg {
  fill: transparent;
}

.hover-primary:hover,
.hover-primary:hover span svg,
.hover-primary:hover span {
  color: #006ffd !important;
  stroke: #006ffd !important;
}

.shadow-text {
  -webkit-box-shadow: inset 0px -15px 20px 0px rgba(255, 255, 255, 1);
  -moz-box-shadow: inset 0px -15px 20px 0px rgba(255, 255, 255, 1);
  box-shadow: inset 0px -15px 20px 0px rgba(255, 255, 255, 1);
  height: 18px;
  width: 100%;
  z-index: 100;
  top: -20px;
  position: relative;
}

.contract-text img {
  max-width: 28rem;
  max-height: 13rem;
}

.contract-text h1 {
  font-size: 3rem;
}

.contract-text h2 {
  font-size: 2.5rem;
}

.contract-text h3 {
  font-size: 2rem;
}

.contract-text h4 {
  font-size: 1.5rem;
}

.contract-text h5 {
  font-size: 1rem;
}

.contract-text {
  all: unset;
  line-height: 3;
}

.contract-text * {
  text-transform: none !important;
}

.p-PaymentDetails-group input {
  border-radius: 12px !important;
}

.normal-case-section p,
.normal-case-section h1,
.normal-case-section h2,
.normal-case-section h3,
.normal-case-section h4,
.normal-case-section span,
.normal-case-section a {
  text-transform: none;
}

@media (min-width: 1710px) {
  .project-root {
    width: 1536px !important;
    margin: 0px auto !important;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .border-card {
    @apply border border-[#d1d6dd] border-opacity-40;
  }

  .border-card-t {
    @apply border-t border-[#d1d6dd] border-opacity-40;
  }

  .border-card-b {
    @apply border-b border-[#d1d6dd] border-opacity-40;
  }

  .border-card-l {
    @apply border-l border-[#d1d6dd] border-opacity-40;
  }
  .border-card-r {
    @apply border-r border-[#d1d6dd] border-opacity-40;
  }
  .btn-primary {
    @apply cursor-pointer rounded-3 border bg-primary py-3 px-5 font-[600] text-white duration-300 hover:border-primary hover:bg-[#EAF2FF] hover:text-primary md:py-1 md:px-3 xl:py-3 xl:px-5;
  }

  .social-btn {
    @apply border-card flex w-full cursor-pointer rounded-2 p-3;
  }
  .social-btn .label {
    @apply w-full text-center normal-case;
  }

  .btn-inspection {
    @apply border-card flex min-w-[7rem] cursor-pointer justify-center rounded-1 py-1 px-4 normal-case;
  }

  .initial-box-style {
    @apply rounded-4 p-4 xl:p-8;
  }

  .flex-center {
    @apply flex items-center justify-center;
  }
}

/* width */
/* ::-webkit-scrollbar {
    width: 5px;
} */

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
