.react-datepicker-wrapper,
.react-datepicker__input-container {
  height: 100%;
}

.react-datepicker {
  border-radius: 7px !important;
}

.react-datepicker__header {
  border-bottom: 0 !important;
  padding-top: 0 !important;
  background-color: transparent !important;
}

.react-datepicker {
  border: 1px solid #d1d6dd !important;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__month-container {
  padding: 16px;
}

.react-datepicker__time-container .react-datepicker__time {
  background: transparent !important;
}

.react-datepicker__time-container {
  border-left: 1px solid #d1d6dd !important;
}

.react-datepicker__header--time {
  border-bottom: 1px solid #d1d6dd !important;
}

.react-datepicker__day-name,
.react-datepicker__day {
  font-size: 16px;
}

.react-datepicker__day-name {
  width: 40px !important;
}

.react-datepicker__day,
.react-datepicker__time-name {
  width: 40px !important;
  height: 40px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 3px;
}

.react-datepicker__day--selected {
  background-color: #006ffd !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: #76aaef !important;
}

.react-datepicker__day-names,
.react-datepicker__week {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.react-datepicker__day-names {
  margin-top: 12px !important;
}

.react-datepicker__day--outside-month {
  background-color: unset !important;
  color: rgba(0, 23, 84, 0.15) !important;
  cursor: not-allowed !important;
}

.react-datepicker__current-month {
  font-size: 16px !important;
  color: #636464 !important;
}

.react-datepicker__navigation--previous {
  left: 24px !important;
  margin-top: 15px;
}

.react-datepicker__navigation--next {
  right: 24px !important;
  margin-top: 15px;
}

.react-datepicker__day--today {
  border: 1px solid #006ffd;
  font-weight: 400 !important;
}

.hasTimeSelect .react-datepicker__navigation--next {
  right: 109px !important;
}

.react-datepicker__navigation-icon {
  top: 4px !important;
}

.react-datepicker__navigation-icon::before {
  border-width: 2px 2px 0 0 !important;
}

/* .react-datepicker__navigation span {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
} */
.react-datepicker-popper {
  border: none !important;
}

.react-datepicker-popper[data-placement^='top'] {
  padding-bottom: 5px !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #006ffd !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-datepicker-popper {
  z-index: 6 !important;
}

.react-datepicker__year-dropdown {
  background: #fff !important;
  border: 1px solid #aeaeae5e !important;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #fff !important;
}

.react-datepicker__year-read-view {
  font-size: 15px;
  margin-top: 5px;
}

.react-datepicker__year-read-view--down-arrow {
  top: 5px !important;
}

.react-datepicker__year-option {
  font-size: 10px;
}

.react-datepicker__year-read-view--down-arrow {
  border-width: 2px 2px 0 0 !important;
}

.react-datepicker__year-read-view--down-arrow {
  display: none !important;
}

.react-datepicker__year-option {
  font-size: 13px;
}
