.invoice-table .table > :not(caption) > * > * {
  border-bottom-width: 0 !important;
}

.invoice-table table {
  border-collapse: separate;
  border-spacing: 0 1em;
}

.invoice-table table tbody td:first-child {
  padding-left: 24px;
}

.invoice-table table thead th:first-child {
  padding-left: 12px;
}

.invoice-table table tbody td {
  padding-top: 24px;
  padding-bottom: 24px;
  min-width: 135px;
  min-height: 73px;
}
