.Toastify {
  position: relative;
  z-index: 1051 !important;
}

.Toastify__toast {
  cursor: default !important;
}

.Toastify__toast--warning,
.Toastify__toast--error,
.Toastify__toast--success {
  position: relative;
}

.Toastify__toast--warning::after,
.Toastify__toast--error::after,
.Toastify__toast--success::after {
  position: absolute;
  top: 18%;
}

.Toastify__toast--success::after {
  content: url('../components/assets/success.svg');
}

.Toastify__toast--error::after {
  content: url('../components/assets/fail.svg');
}

.Toastify__toast--warning::after {
  content: url('../components/assets/warning.svg');
}

.Toastify__toast-body {
  padding-left: 50px;
}
