.swiper-pagination-fraction {
  width: 60px !important;
  left: calc(50% - 25px) !important;
  background: #62646480;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
}
.report-gallery .swiper-pagination-fraction {
  background: transparent !important;
  color: black !important;
  margin-left: -5px;
  margin-bottom: -4px;
}

/* .swiper-gallery .swiper-button-prev,
.swiper-gallery .swiper-button-next {
  display: none;
} */

@media screen and (min-width: 1024px) {
  .swiper-gallery .swiper-button-prev,
  .swiper-gallery .swiper-button-next {
    display: block;
  }
}

/* .swiper-button-next,
.swiper-button-prev {
    background-color: #006FFD !important;
    border-radius: 50%;
    width: 28px;
    height: 28px;
}

.swiper-button-prev:after,
.swiper-button-next:after {
    font-size: 9px;
    color: #fff;
    font-weight: bold;
} */
.swiper-button-disabled {
  background-color: #d1d6dd !important;
}

.category-list-slider .swiper-slide {
  width: auto !important;
}
